var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"tour-supplier page_bg_grey blank-aside-js",class:(_vm.isOpenAside=='true')?'blank-aside':''},[_c('p',{staticClass:"des-title"},[_vm._v(_vm._s(_vm.$t("TOURISM_SUPPLIER.TITLE")))]),_c('div',{staticClass:"table-content-part"},[_c('div',{staticClass:"search-bar"},[_c('p',{staticClass:"search-label"},[_vm._v(_vm._s(_vm.$t("COMMON.SEARCH")))]),_c('v-text-field',{staticClass:"search-form",attrs:{"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"cus-table",attrs:{"headers":_vm.headers,"items":_vm.tourSupplier_data,"search":_vm.search},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{staticClass:"cus-checkbox",attrs:{"color":"#0D3856"},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1)]}},{key:"item.display",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{staticClass:"cus-checkbox",attrs:{"color":"#0D3856"},model:{value:(item.display),callback:function ($$v) {_vm.$set(item, "display", $$v)},expression:"item.display"}})],1)]}},{key:"item.flight",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{staticClass:"cus-checkbox",attrs:{"color":"#0D3856"},model:{value:(item.flight),callback:function ($$v) {_vm.$set(item, "flight", $$v)},expression:"item.flight"}})],1)]}},{key:"item.noftshon",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{staticClass:"cus-checkbox",attrs:{"color":"#0D3856"},model:{value:(item.noftshon),callback:function ($$v) {_vm.$set(item, "noftshon", $$v)},expression:"item.noftshon"}})],1)]}},{key:"item.organized_tours",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{staticClass:"cus-checkbox",attrs:{"color":"#0D3856"},model:{value:(item.organized_tours),callback:function ($$v) {_vm.$set(item, "organized_tours", $$v)},expression:"item.organized_tours"}})],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"link category__button row-btn",on:{"click":function($event){return _vm.saveOne(item.id)}}},[_vm._v(_vm._s(_vm.$t("COMMON.SAVE")))])]}},{key:"item.tour_supplier_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"js-cus-dropdownbtn",attrs:{"id":item.id},on:{"click":function($event){return _vm.show_dropdown(item.id)}}},[_vm._v(" "+_vm._s(item.tour_supplier_name)+" ")]),_c('div',{ref:item.id,staticClass:"dropdown-content supplier-name-content"},[_c('h4',{staticClass:"mdtitle-font-size"},[_vm._v(_vm._s(_vm.$t("TOURISM_SUPPLIER.ARKIO")))]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-type1 arkie"},[_c('label',{staticClass:"smtitle-font-size",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("TOURISM_SUPPLIER.HP")))]),_c('input',{staticClass:"input",attrs:{"type":"text"}})]),_c('div',{staticClass:"flex-type1 arkie"},[_c('label',{staticClass:"smtitle-font-size",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("COMMON.PHONE")))]),_c('input',{staticClass:"input",attrs:{"type":"text"}})])]),_c('h4',{staticClass:"mdtitle-font-size"},[_vm._v(_vm._s(_vm.$t("TOURISM_SUPPLIER.PROFIT_PERCENTAGES")))]),_c('h4',{staticClass:"mdtitle-font-size"},[_vm._v(_vm._s(_vm.$t("TOURISM_SUPPLIER.CONTACTS")))]),_c('h4',{staticClass:"mdtitle-font-size"},[_vm._v(_vm._s(_vm.$t("TOURISM_SUPPLIER.BANK_DETAILS")))])])]}}],null,true)})],1),_c('a',{staticClass:"link category__button new-btn",on:{"click":function($event){return _vm.saveAll()}}},[_vm._v(_vm._s(_vm.$t("COMMON.NEW")))]),_c('a',{staticClass:"link category__button total-save-btn",on:{"click":function($event){return _vm.saveAll()}}},[_vm._v(_vm._s(_vm.$t("COMMON.SAVE")))])])}
var staticRenderFns = []

export { render, staticRenderFns }