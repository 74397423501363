<template>
  <main class="tour-supplier page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="des-title">{{ $t("TOURISM_SUPPLIER.TITLE") }}</p>
    <div class="table-content-part">
      <div class="search-bar">
        <p class="search-label">{{ $t("COMMON.SEARCH") }}</p>
        <v-text-field
          v-model="search"
          single-line
          hide-details
          class="search-form"
        ></v-text-field>
      </div>
      <v-data-table :headers="headers" :items="tourSupplier_data" class="cus-table" :search="search">
        <template v-slot:[`item.active`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <v-checkbox v-model="item.active" color="#0D3856" class="cus-checkbox"></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.display`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <v-checkbox v-model="item.display" color="#0D3856" class="cus-checkbox"></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.flight`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <v-checkbox v-model="item.flight" color="#0D3856" class="cus-checkbox"></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.noftshon`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <v-checkbox v-model="item.noftshon" color="#0D3856" class="cus-checkbox"></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.organized_tours`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <v-checkbox v-model="item.organized_tours" color="#0D3856" class="cus-checkbox"></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <a class="link category__button row-btn" @click="saveOne(item.id)">{{ $t("COMMON.SAVE") }}</a>
        </template>
        <template v-slot:[`item.tour_supplier_name`]="{ item }">
          <div 
            class="js-cus-dropdownbtn" 
            @click="show_dropdown(item.id)" 
            :id="item.id"

          >
            {{item.tour_supplier_name}}
          </div>
          <div class="dropdown-content supplier-name-content" :ref="item.id">
            <h4 class="mdtitle-font-size">{{ $t("TOURISM_SUPPLIER.ARKIO") }}</h4>
            <div class="d-flex">
              <div class="flex-type1 arkie">
                <label class="smtitle-font-size" for="">{{ $t("TOURISM_SUPPLIER.HP") }}</label>
                <input type="text" class="input">
              </div>
              <div class="flex-type1 arkie">
                <label class="smtitle-font-size" for="">{{ $t("COMMON.PHONE") }}</label>
                <input type="text" class="input">
              </div>
            </div>
            
            <h4 class="mdtitle-font-size">{{ $t("TOURISM_SUPPLIER.PROFIT_PERCENTAGES") }}</h4>
            <h4 class="mdtitle-font-size">{{ $t("TOURISM_SUPPLIER.CONTACTS") }}</h4>
            <h4 class="mdtitle-font-size">{{ $t("TOURISM_SUPPLIER.BANK_DETAILS") }}</h4>
          </div> 
        </template>
      </v-data-table>
    </div>
    <a class="link category__button new-btn" @click="saveAll()">{{ $t("COMMON.NEW") }}</a>
    <a class="link category__button total-save-btn" @click="saveAll()">{{ $t("COMMON.SAVE") }}</a>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "TourSupplier",
  data() {
    return {
      search: "",
      tag_search: "",
      visible: false,
      headers: [
        {
          text: this.$t("TOURISM_SUPPLIER.PROVIDER_NUMBER"),
          align: "center ",
          sortable: false,
          value: "id"
        },
        {
          text: this.$t("TOURISM_SUPPLIER.SUPPLIER_CODE"),
          align: "center ",
          sortable: false,
          value: "amadeus_code_supplier"
        },
        {
          text: this.$t("TOURISM_SUPPLIER.NAME_PROVIDER"),
          align: "center ",
          sortable: false,
          value: "tour_supplier_name"
        },
        {
          text: this.$t("TOURISM_SUPPLIER.HP_DEALER"),
          align: "center ",
          sortable: false,
          value: "business_id"
        },
        {
          text: this.$t("COMMON.PHONE"),
          align: "center ",
          sortable: false,
          value: "phone"
        },
        {
          text: this.$t("TOURISM_SUPPLIER.CONTACT"),
          align: "center ",
          sortable: false,
          value: "contact_person"
        },
        {
          text: this.$t("TOURISM_SUPPLIER.MOBILE_CONTACT"),
          align: "center ",
          sortable: false,
          value: "contact_person_phone"
        },
        {
          text: this.$t("COMMON.ACTIVE"),
          align: "center ",
          sortable: false,
          value: "active"
        },
        {
          text: this.$t("TOURISM_SUPPLIER.DISPLAY"),
          align: "center ",
          sortable: false,
          value: "display"
        },
        {
          text: this.$t("TOURISM_SUPPLIER.FLIGHTS"),
          align: "center ",
          sortable: false,
          value: "flight"
        },
        {
          text: this.$t("TOURISM_SUPPLIER.VACATIONERS"),
          align: "center ",
          sortable: false,
          value: "noftshon"
        },
        {
          text: this.$t("TOURISM_SUPPLIER.ORGANIZED"),
          align: "center ",
          sortable: false,
          value: "organized_tours"
        },
        {
          text: "",
          align: "center ",
          sortable: false,
          value: "action"
        },
      ],
      selectedTag: "",
      oldAirId: "",
      id_showed: "",
      filteredList: [],
      addform: {
        name: "",
        code: "",
        active: true
        }
    };
  },
  computed: {
    ...mapState({
      tourSupplier_data: state => state.tourSupplier.tourSupplierData_receipts
    }),
    
  },
  async created() {
    if (this.tourSupplier_data == "") {
      await this.getTourSupplier();
    }

    // this.tourSupplier_data.forEach(item => {
    //   item.newTags = [];
    //   item.tags.forEach(itemTag => {
    //     this.tags_data.forEach(tag => {
    //       if(tag.id == itemTag) {
    //         item.newTags.push({id: tag.id, name: tag.name});
    //       }
    //     })
    //   })
    // });
    // this.filteredList = this.tags_data;
  },
  mounted () {
    document.addEventListener('click', this.close);
  },
  beforeDestroy () {
    document.removeEventListener('click',this.close);
  },
  methods: {
    ...mapActions("tourSupplier", {
      getTourSupplier: "getTourSupplier",
      getTags: "getTags",
      changeTagsState: "changeTagsState",
      save_OneDes: "save_OneDes",
      save_AllDes: "save_AllDes",
      addAirportstore: "addAirportstore"
    }),

    /* multi-select dropdown show and close */
    show_dropdown(id) {
      if (this.oldAirId == id) {
        if (this.$refs[id].style.display == "none") {
          this.$refs[id].style.display = "block";
          this.$refs[id].style.top = document.getElementById(id).getBoundingClientRect().top + 10 + "px";
          this.$refs[id].style.left = document.getElementById(id).getBoundingClientRect().left - 600 + "px";
          this.id_showed = id;
        }
        else {
          this.$refs[id].style.display = "none"; 
          this.id_showed = ""; 
          this.reset_addForm();
        }
         
      }
      else {
        if (this.oldAirId != "") {
          this.$refs[this.oldAirId].style.display = "none";
          this.reset_addForm();
          
        }
        
        this.$refs[id].style.display = "block";
        this.$refs[id].style.top = document.getElementById(id).getBoundingClientRect().top + 10 + "px";
        this.$refs[id].style.left = document.getElementById(id).getBoundingClientRect().left - 600 + "px";
        this.id_showed = id;
        this.oldAirId = id;
      }
    },

    close (e) {
      if(this.id_showed && this.id_showed != "") {
        const id = this.id_showed;

        if (!this.$refs[id].contains(e.target) && !e.target.classList.contains("js-cus-dropdownbtn")) {
          this.$refs[id].style.display = "none"; 
          this.id_showed = ""; 
          this.reset_addForm();
        }
      }
    },
    /*  */


    /* save one destination */
    saveOne(id) {
      this.save_OneDes({id:id});
    },
    /*  */

    /* save all destination */
    saveAll(id) {
      this.save_AllDes();
    },
    /*  */
     
    /* change tags  */
    changeTags(id, newIds) {
      this.changeTagsState({id: id, tag_data: newIds});
      this.tourSupplier_data.forEach(item => {
        if(item.id == id) {
          item.newTags = [];
          newIds.forEach(id => {
            this.tags_data.forEach(tag => {
              if(tag.id == id) {
                item.newTags.push({id: tag.id, name: tag.name});
              }
            })
          })
        }
      });
    },
    /*  */

    /* add airport to destination */
    add_airport(id) {
      let new_airport = {
        name: this.addform.name,
        code: this.addform.code.toUpperCase(),
        active: this.addform.active
      }
      this.addAirportstore({id: id, airport_data: new_airport});
      this.reset_addForm();
    },
    /*  */

    /* search tags and reset search */

    reset_addForm() {
      this.addform.name = "";
      this.addform.code = "";
      this.addform.active = true;
    }
    /*  */
  }
};
</script>

<style scoped lang="scss">
  .tour-supplier {
    width: unset;
    height: 100%;
    text-align: center;
    color: #002036;
    position: relative;
  }

  .des-title {
    font-size: 30px;
    margin-top: 10px;
  }

  .js-cus-dropdownbtn:hover {
    cursor: pointer;
  }
  .dropdown-content {
    background: #E3E3E3;
    border: 1px solid #70707085;
    color: #002036;
    width: 40vw;
    max-width: 780px;
    height: 708px;
    padding: 14px 28px;
    text-align: initial;

    .arkie {
      width: 30%;
      
      .input {
        height: 21px;
      }
    }
  }

  .new-btn {
    color: #fff;
    background: #E2B629;
    margin: 10px 50px;
    border-radius: 4px;
  }

  .total-save-btn {
    color: white;
    margin-top: 18px;
    max-width: 530px;
  }

 

</style>